import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Google } from '@mui/icons-material';

// Import action creators
import { fbSignInWithEmailAndPassword, fbSignInWithGoogle } from '../actions/auth';

const Login = ({ fbSignInWithEmailAndPassword, fbSignInWithGoogle, isAuthenticated }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const redirectFrom = sessionStorage.getItem('loginRedirect') || '/home';
    // console.log(redirectFrom);
    
    
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        fbSignInWithEmailAndPassword(email, password);
    };

    const handleGoogleLogin = async () => {
        fbSignInWithGoogle();
    };

    // Use useEffect to perform navigation after component has been rendered
    useEffect(() => {
        if (isAuthenticated) {
            navigate(redirectFrom);
        }
    }, [isAuthenticated, redirectFrom, navigate]);

    return (
        <Box sx={{ mt: '10px' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F2F2F2', p: 2 }}>
                <Grid item xs={4} sm={4}>
                    <Typography component="h1" variant="h5" align="center">
                        Sign In
                    </Typography>
                    <form onSubmit={onSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={onChange}
                            required
                        />
                        <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
                            Login
                        </Button>
                    </form><br/>
                    <Typography align="center" sx={{ mt: 2 }}>
                        Don't have an account? <Link to='/signup'>Sign Up</Link>
                    </Typography><br/>
                    <Typography align="center">
                        Forgot your Password? <Link to='/reset_password'>Reset Password</Link>
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2}></Grid>
                <Grid item xs={3} sm={3}>
                    <Button
                        variant="contained"
                        startIcon={<Google />}
                        style={{ backgroundColor: '#4285F4', color: 'white' }}
                        onClick={handleGoogleLogin}
                    >
                        Sign in with Google
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { fbSignInWithEmailAndPassword, fbSignInWithGoogle })(Login);
