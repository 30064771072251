import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fbSignInWithGoogle, fbRegisterWithEmailAndPassword } from '../actions/auth';
import { Alert, Box, Button, Container, Grid, TextField, Typography, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { Google } from '@mui/icons-material';


const Signup = ({ fbSignInWithGoogle, fbRegisterWithEmailAndPassword, isAuthenticated, isRegistered }) => {
    // console.log(isRegistered);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();

    const { email, password, confirmPassword } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        setPasswordError('');
        await fbRegisterWithEmailAndPassword(email, password);
    };

    const handleGoogleLogin = () => {
        fbSignInWithGoogle();
    };

    let alertShown = false;

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home');
        } else if (isRegistered && !alertShown) {
            window.alert("You have successfully registered! 🎉")
            navigate('/login');
            alertShown = true;
        }

    }, [isAuthenticated, isRegistered, navigate]);


    return (
        <Box sx={{ mt: '10px' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F2F2F2', p: 2 }}>
                <Grid item xs={4} sm={4}>
                    <Typography component='h1' variant='h5' align='center' gutterBottom>
                        Sign Up
                    </Typography>
                    <Typography align='center' gutterBottom>
                        Create your Account
                    </Typography>
                    <form onSubmit={onSubmit}>
                        <TextField
                            margin='normal'
                            fullWidth
                            id='email'
                            label='Email*'
                            type='email'
                            name='email'
                            value={email}
                            onChange={onChange}
                            required
                        />
                        <TextField
                            margin='normal'
                            fullWidth
                            id='password'
                            label='Password*'
                            type='password'
                            name='password'
                            value={password}
                            onChange={onChange}
                            minLength='6'
                            required
                        />
                        <TextField
                            margin='normal'
                            fullWidth
                            id='confirmPassword'
                            label='Confirm Password*'
                            type='password'
                            name='confirmPassword'
                            value={confirmPassword}
                            onChange={onChange}
                            minLength='6'
                            required
                            error={passwordError !== ''}
                            helperText={passwordError}
                        />
                        <Button variant='contained' type='submit' fullWidth sx={{ mt: 2 }}>
                            Register
                        </Button>
                    </form>
                    <Typography align='center' sx={{ mt: 3 }}>
                        Already have an account? <Link to='/login'>Sign In</Link>
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2}></Grid>
                <Grid item xs={3} sm={3} sx={{}}>
                    <Button
                        variant="contained"
                        startIcon={<Google />}
                        style={{ backgroundColor: '#4285F4', color: 'white' }}
                        onClick={handleGoogleLogin}
                    >
                        Sign in with Google
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isRegistered: state.auth.isRegistered,
});

export default connect(mapStateToProps, { fbSignInWithGoogle, fbRegisterWithEmailAndPassword })(Signup);
