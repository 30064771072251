import React, { useState, useEffect } from 'react';
import FooterPage from './FooterPage';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faVoteYea } from '@fortawesome/free-solid-svg-icons';

// locals
const breakColor = 'lightgrey'
const breakWidth = '2px'

const About = ({ isAuthenticated }) => {
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [isAuth]);



  return (
    <div>
    <Box sx={{ backgroundColor: 'WhiteSmoke' }}>
      {/* <Box sx={{p:0.5, backgroundColor: breakColor}}></Box> */}
      {/* title box */}
      <Box sx={{ p: 10}}>
        <Typography variant="h3" align="center" sx={{ mb: 2 }}>
          ChartsMaster
        </Typography>
        <Typography variant="h5" align="center" sx={{ mb: 6 }}>
          Stock market barometer made possible by love of charts
        </Typography>
      </Box>
      
      <Box sx={{p:breakWidth, backgroundColor: breakColor}}></Box>
      {/* intro box */}
      <Box sx={{p: 10, m: 5}}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Candlestick chart patterns are used by many traders to detect trends in stock prices.
            Chartsmaster is designed to help traders practice reading chart patterns and to vote on future trends.
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            To reward skilled chartists and dedicated users, candle coin token ($CANDL) is created
            and will be distributed in proportion to the voter's reputation or the user's activity on platform.
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Vote results are displayed on the leaderboard page and are used by the market participants.
          </Typography>
          {isAuth ? (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                For more details visit:
                <a href="https://docs.chartsmaster.com/" target="_blank" rel="noopener noreferrer"> Chartsmaster Docs </a>
              </Typography>
              <Typography variant="h6">
                To learn about Candle Coin utility visit:
                <a href="https://coin.chartsmaster.com/" target="_blank" rel="noopener noreferrer"> Candle Coin Docs </a>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Sign up or login to participate in user reward program.
              </Typography>
              <Button component={Link} to="/login" variant="contained" sx={{ mr: 2 }}>Login</Button>
              <Button component={Link} to="/signup" variant="contained">Signup</Button>
              <hr />
              <Typography variant="h6" sx={{ mt: 2 }}>
                For detailed explanation on each functionality visit:
                <a href="https://docs.chartsmaster.com/" target="_blank" rel="noopener noreferrer"> Chartsmaster Docs </a>
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                To learn about Candle Coin utility visit:
                <a href="https://coin.chartsmaster.com/" target="_blank" rel="noopener noreferrer"> Candle Coin Docs </a>
              </Typography>
            </>
          )}
      </Box>
      
      <Box sx={{p:breakWidth, backgroundColor: breakColor}}></Box>
      {/* gym box */}
      <Box sx={{p: 10}}>
        <Grid container spacing={4}>

          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to="/gym" style={{ textDecoration: 'none' }}>
            <Typography variant="h3" align="center" sx={{ mb: 2, color: 'red' }}>Gym</Typography>
            <FontAwesomeIcon icon={faDumbbell} style={{ fontSize: 48 }} />
          </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Gym is designed to help traders practice reading candlestick patterns.
            </Typography>
            <Typography variant="h6">
              To find more details on gym setup visit:
              <a href="https://docs.chartsmaster.com/gym" target="_blank" rel="noopener noreferrer"> Gym Docs </a>
            </Typography>
          </Grid>
        </Grid>

      </Box>
      
      <Box sx={{p:breakWidth, backgroundColor: breakColor}}></Box>
      {/* vote box */}
      <Box sx={{p: 10}}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to="/vote" style={{ textDecoration: 'none' }}>
              <Typography variant="h3" align="center" sx={{ mb: 2, color: 'green' }}>Vote</Typography>
              <FontAwesomeIcon icon={faVoteYea} style={{ fontSize: 48 }} />
            </Link>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Here registered chartists can vote on future trends using price and volume charts.
            </Typography>
            <Typography variant="h6">
              To find more details on voting process visit:
              <a href="https://docs.chartsmaster.com/vote" target="_blank" rel="noopener noreferrer"> Vote Docs </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{p:breakWidth, backgroundColor: breakColor}}></Box>

      </Box>
      <FooterPage />
      </div>
      );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(About);
