import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, TextField, Button, Grid, Box, Paper } from '@mui/material';
import { confirmPasswordReset } from 'firebase/auth';

// locals
import { fbResetPassword } from '../actions/auth';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = () => {
    if (!email) {
      setMessage('Please enter your email.');
      return;
    }
    
    try {
      fbResetPassword(email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setMessage('Error resetting password. Please try again.');
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Typography color="text.secondary" variant="body1">
                {message}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography align="center">
              <Link to="/login">Back to Login</Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
