
import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

const theme = createTheme();

const TickerSymbol = styled('span')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(1),
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.tooltip,
}));

export const ChartComponent = (props) => {

    const {
        tickerSymbol,
        candleSeriesData,
        volumeSeriesData,
        candleColors: {
            upColor = '#26a69a',
            downColor = '#ef5350',
        } = {},
        volumeColors: {
            color = '#26a69a',
        } = {},
        textColor = 'black'
    } = props;

    const chartContainerRef = useRef();
    const tickerRef = useRef();

    const minHeight = 400;
    const dynamicHeight = window.innerHeight * 0.7;
    const chartHeight = dynamicHeight > minHeight ? dynamicHeight : minHeight;

    useEffect(() => {
        if (chartContainerRef.current) {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    textColor: textColor,
                },
                width: chartContainerRef.current.clientWidth,
                height: chartHeight,
            });

            const candlestickSeries = chart.addCandlestickSeries({
                borderVisible: false,
                wickUpColor: upColor,
                wickDownColor: downColor,
            });

            const volumeSeries = chart.addHistogramSeries({
                color: color,
                priceFormat: {
                    type: "volume"
                },
                priceScaleId: "", 
                scaleMargins: {
                    top: 0.7,
                    bottom: 0
                }
            });
            volumeSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.7,
                    bottom: 0
                }
            });

            if (Array.isArray(candleSeriesData)) {
                const formattedCandleSeriesData = candleSeriesData.map(dataPoint => ({
                    time: dataPoint.time,
                    open: dataPoint.open,
                    high: dataPoint.high,
                    low: dataPoint.low,
                    close: dataPoint.close,
                    color: dataPoint.color || (dataPoint.close >= dataPoint.open ? upColor : downColor),
                    wickColor: dataPoint.color || (dataPoint.close >= dataPoint.open ? upColor : downColor)
                }));
            
                candlestickSeries.setData(formattedCandleSeriesData);
            }
            volumeSeries.setData(volumeSeriesData);

            chart.timeScale().fitContent();

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        }
    }, [upColor, downColor, color, candleSeriesData, volumeSeriesData, textColor]);

    useEffect(() => {
        if (tickerRef.current) {
            tickerRef.current.innerText = tickerSymbol;
        }
    }, [tickerSymbol]);

    return (
        <div style={{ position: 'relative' }}>
            <div
                ref={chartContainerRef}
                style={{ height: chartHeight }}
            />
            <ThemeProvider theme={theme}>
            <TickerSymbol>{tickerSymbol}</TickerSymbol>
            </ThemeProvider>
        </div>
    );
};
