
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { connect } from 'react-redux';
import { debounce } from "lodash";
import { styled } from '@mui/material/styles';
import { Button, ButtonGroup, Box, Card, CardContent, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Tooltip, Alert, ToggleButton, } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// Local resources
import { ChartComponent } from "./TVChart";
import { QA1, BasicInfo, ErrorBoundary, LinearDeterminate } from './Utils';
import { ChartSettings, LastVoteCard, BalanceCard, VoteButtons } from "../components/UtilityComps";

// declare freq/hist/hrzn button group
const frequencyList = [
  { name: 'D', value: 'D' },
  { name: 'W', value: 'W' },
  { name: 'M', value: 'M' },
];

const histlenList = [
  { name: '1Y', value: '1Y' },
  { name: '2Y', value: '2Y' },
  { name: '5Y', value: '5Y' },
];

const horizonList = [
  { name: '1M', value: '1M' },
  { name: '2M', value: '2M' },
  { name: '3M', value: '3M' },
];


// button disable duration 1.2 sec.
const cooldownDuration = 1200

const GymPage = ({ user_id, isAuthenticated }) => {
  const location = useLocation();
  sessionStorage.setItem('loginRedirect', location.pathname);
  
  const API_URL = process.env.REACT_APP_API_BASE_URL;

  const [formData, setValues] = useState({
    ticker: 'SPY',
    priceDataShow: [],
    priceDataHide: [],
    volDataShow: [],
    volDataHide: [],
    chartClose: 0,
    trend: '',
    dollarDelta: 0
  });

  const [candleType, setCandleType] = useState(localStorage.getItem('candleType') || frequencyList[0].value);
  const [histlen, setHistlen] = useState(localStorage.getItem('histlen') || histlenList[0].value);
  const [horizon, setHorizon] = useState(localStorage.getItem('horizon') || horizonList[0].value);
  const [score, setScore] = useState(+localStorage.getItem('score') || 0);
  const [playMoney, setPlayMoney] = useState(+localStorage.getItem('playMoney') || 1000);
  const [loading, setLoading] = useState(false);
  const [answer, showAnswer] = useState(false);
  const [voteButtonCooldown, setVoteButtonCooldown] = useState(false);
  const [nextButtonCooldown, setNextButtonCooldown] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [lastVoteResult, setLastVoteResult] = useState(null);

  useEffect(() => {
    // reset params in local storage
    localStorage.setItem('candleType', candleType);
    localStorage.setItem('histlen', histlen);
    localStorage.setItem('horizon', horizon);
    localStorage.setItem('score', score);
    localStorage.setItem('playMoney', playMoney)    
  }, [candleType, histlen, horizon, score, playMoney]);

  // State for alerts
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success', // Default severity
  });

  // setting focus
  const gymPageRef = useRef(null);
  useEffect(() => {
    if (gymPageRef.current) {
      gymPageRef.current.focus();
    }
  }, []);

  // fetch market data
  const debouncedFetchData = useRef(
    debounce(async (params) => {
      try {
        setLoading(true);
        const res = await axios.get(`${API_URL}/api/loadGymData/`, {
          params: {
            candleType: params.candleType,
            histlen: params.histlen,
            horizon: params.horizon,
          }
        });
        setValues({
          ticker: res.data.ticker,
          priceDataShow: res.data.priceDataShow,
          priceDataHide: res.data.priceDataHide,
          volDataShow: res.data.volDataShow,
          volDataHide: res.data.volDataHide,
          chartClose: res.data.chartClose,
          trend: res.data.trend,
          dollarDelta: res.data.dollarDelta
        });
      } catch (err) {
        console.log("Error fetching data:", err);
        setAlert({
          open: true,
          message: 'Failed to fetch data. Please try again later.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
        setShowProgressBar(false);
        showAnswer(false);
      }
    }, cooldownDuration)
  ).current;
  
  useEffect(() => {
    debouncedFetchData({
      candleType: candleType,
      histlen: histlen,
      horizon: horizon,      
    });
  }, [candleType, histlen, horizon]);
  
  // handle vote buttons click
  const handleVote = (e, vote) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // todo: implement sideways move
    if (formData.trend === null) {
      handleNextChart();
    }

    // Check if the vote matches the trend
    if ((vote === 'up' && formData.trend === 'up') || (vote === 'down' && formData.trend === 'down')) {
        // If the vote matches the trend, add to score and money

        setScore(prevScore => prevScore + 1);
        setPlayMoney(prevPlayMoney => prevPlayMoney + formData.dollarDelta);
        setLastVoteResult('correct');
      } else {
        // If the vote does not match the trend, subtract from score and money

        setScore(prevScore => prevScore - 1);
        setPlayMoney(prevPlayMoney => prevPlayMoney - formData.dollarDelta);
        setLastVoteResult('wrong');
      }

    showAnswer(true);
    setVoteButtonCooldown(true);
    setNextButtonCooldown(true);

    setTimeout(() => {
      setNextButtonCooldown(false);
    }, cooldownDuration);
  };

  // actions to take for next chart
  const handleNextChart = () => {
    setShowProgressBar(true);
    setVoteButtonCooldown(true);
    setNextButtonCooldown(true);
    debouncedFetchData({
      candleType: candleType,
      histlen: histlen,
      horizon: horizon,
    });

    setTimeout(() => {
      setVoteButtonCooldown(false);
      setNextButtonCooldown(false);
      showAnswer(false);
      setLastVoteResult(null);
    }, cooldownDuration);
  };

  // listen to UP & DOWN arrow keys
  useEffect(() => {
    const handleVoteKeyPress = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        handleVote(event, event.key === 'ArrowUp' ? 'up' : 'down');
      }
    };

    document.addEventListener("keydown", handleVoteKeyPress);

    return () => {
      document.removeEventListener("keydown", handleVoteKeyPress);
    };
  }, [handleVote]);

  // listen to RIGHT arrow keys
  useEffect(() => {
    const handleNextChartKeyPress = (event) => {
      if (event.key === " " || event.key === "ArrowRight") {
          handleNextChart();
          debouncedFetchData({
            candleType: candleType,
            histlen: histlen,
            horizon: horizon,            
          }); // Debounced fetch data function
      }
    };

    document.addEventListener("keydown", handleNextChartKeyPress);

    return () => {
      document.removeEventListener("keydown", handleNextChartKeyPress);
    };
  }, [candleType, histlen, horizon]);

  // handle save session
  const saveSession = async () => {
    try {
      setScore(score);
      setPlayMoney(playMoney);

      if (isAuthenticated) {
        const userInfo = {
          userid: user_id,
          score: score,
          balance: Math.round(((playMoney) + Number.EPSILON) * 100) / 100,
        }
        await axios.post(`${API_URL}/api/scores/`, userInfo);
        // Trigger success alert
        setAlert({
          open: true,
          message: 'Session saved successfully',
          severity: 'success',
        });
      }
    } catch (error) {
      // Trigger failure alert
      setAlert({
        open: true,
        message: 'Failed to save session',
        severity: 'error',
      });
    }
  };

  // handle reset session
  const handleReset = () => {
    setScore(0);
    setPlayMoney(1000);
    setLastVoteResult(null);
    localStorage.setItem('score', 0);
    localStorage.setItem('playMoney', 1000)    
  }

  // Alert close handler
  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const handleChartSetting = (newValue, setFunction) => {
    setFunction(newValue);
  };
  
  // material-ui theme
  // const theme = useTheme();
  // const classes = useStyles();

  // setup chart height
  const minHeight = 400; // Minimum height in pixels
  const dynamicHeight = window.innerHeight * 0.7; // 70% of viewport height
  const chartHeight = dynamicHeight > minHeight ? dynamicHeight : minHeight;


  return (
    <div>
        <Box mt={1}>
          <Box mb={1} display="flex" justifyContent='space-between' style={{ backgroundColor: "#f5f5f5"}}>
            {/* Candle, History, Horizon Button Groups */}
            <ChartSettings
              frequencyList={frequencyList}
              histlenList={histlenList}
              horizonList={horizonList}
              candleType={candleType}
              histlen={histlen}
              horizon={horizon}
              handleChartSetting={handleChartSetting}
              setCandleType={setCandleType}
              setHistlen={setHistlen}
              setHorizon={setHorizon}
            ></ChartSettings>

            {/* Gain and Loss tracker */}
            <LastVoteCard
              lastVoteResult={lastVoteResult}
              formData={formData}
            ></LastVoteCard>

            {/* Balance and Score */}
            <BalanceCard
              playMoney={playMoney}
              score={score}
            ></BalanceCard>
          </Box>

          {/* Chart Component */}
          <Card variant="outlined" style={{ height: 'fit-content' }}>
            <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
              <ErrorBoundary>
                <div style={{ height: chartHeight}}>
                  {(loading || formData.priceDataShow.length === 0 || formData.priceDataHide.length === 0) && (
                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                      <CircularProgress style={{ color: 'white' }} />
                    </Box>
                  )}
                  {!loading && (formData.priceDataShow.length > 0 || formData.priceDataHide.length > 0) && (
                    <ChartComponent
                    tickerSymbol=""
                    candleSeriesData={answer ? formData.priceDataHide : formData.priceDataShow}
                    volumeSeriesData={answer ? formData.volDataHide : formData.volDataShow}
                    />
                  )}
                </div>
              </ErrorBoundary>
              {showProgressBar && <LinearDeterminate />}
            </CardContent>
          </Card>

          {/* Vote Buttons and Save Session Button */}
          <Box mt={3} display="flex" justifyContent="space-between" style={{ backgroundColor: "#f5f5f5"}}>
            <Box></Box>
            <VoteButtons
              handleVote={handleVote}
              handleNextChart={handleNextChart}
              voteButtonCooldown={voteButtonCooldown}
              nextButtonCooldown={nextButtonCooldown}
              loading={loading}
            ></VoteButtons>

            <Box display="flex" alignItems="center">
                <Box>
                    <Tooltip title={isAuthenticated ? "SAVE SESSION!" : "Please sign in to save session."} placement="top">
                        <Button
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            onClick={saveSession}
                            size="small"
                            // sx={{ color: 'darkgrey' }}
                        >
                            SAVE
                        </Button>
                    </Tooltip>
                </Box>
                <Box ml={1}>
                    <Tooltip title="RESET SCORE" placement="top">
                        <Button
                            variant="outlined"
                            startIcon={<RefreshIcon />}
                            onClick={handleReset}
                            size="small"
                            // sx={{ color: 'darkgray'}}
                        >
                            RESET
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            </Box>
        </Box>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseAlert} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user_id: state.auth.user_id,
});

export default connect(mapStateToProps)(GymPage);