import React from 'react';
import { Typography } from '@mui/material';

const UnderDevelopmentPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Typography variant="h4" gutterBottom>
        This page is under development
      </Typography>
      <Typography variant="body1">
        We're sorry, but this page is currently under development. Please check back later.
      </Typography>
    </div>
  );
};

export default UnderDevelopmentPage;
