import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import "../styles/FooterPage.css";

const FooterPage = () => {
  return (
    <Grid
      container
      className="footer-main"
      component="footer"
      sx={{
        bgcolor: "rgba(87, 154, 156, 0.295)",
        py: 2,
        px: 3,
      }}
    >
      <Grid item xs={12} sm={6} mb={2} container alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body1">Contact us: chartsmaster1@gmail.com</Typography>
          <Typography variant="body1">
            Connect with us @{" "}
            <Link href="https://linktr.ee/chartsmaster1" target="_blank">
              linktree
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} container justifyContent="flex-end">
        <Typography variant="body1">
          <Link href="/terms-and-conditions">Terms and Conditions</Link>
          <br />
          <Link href="/privacy-policy">Privacy Policy</Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()}{" "}
          <Link href="https://www.chartsmaster.com">chartsmaster.com</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterPage;
