import React from 'react';
import { Box, Button, ButtonGroup, ToggleButton } from '@mui/material';
import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


export const VoteButtons = ({ handleVote, handleNextChart, voteButtonCooldown, nextButtonCooldown, loading }) => {
    return (
        <Box display="flex">
            <Box>
                <Tooltip title="UP ARROW KEY" placement="top">
                    <Button
                        className="btn-up"
                        variant='outlined'
                        onClick={(e) => {
                            handleVote(e, 'up');
                        }}
                        disabled={voteButtonCooldown || loading}
                        size="small"
                        sx={{ bgcolor: 'green', color: 'white', '&:hover': { bgcolor: 'darkgreen' } }}
                    >
                        UP
                    </Button>
                </Tooltip>
            </Box>
            <Box ml={1}>
                <Tooltip title="DOWN ARROW KEY" placement="top">
                    <Button
                        className="btn-dn"
                        variant='outlined'
                        onClick={(e) => {
                            handleVote(e, 'down');
                        }}
                        disabled={voteButtonCooldown || loading}
                        size="small"
                        sx={{ bgcolor: 'red', color: 'white', '&:hover': { bgcolor: 'darkred' } }}
                        >
                        DOWN
                    </Button>
                </Tooltip>
            </Box>
            <Box ml={1}>
                <Tooltip title="RIGHT ARROW KEY" placement="top">
                    <Button
                        className="btn-sk"
                        variant='outlined'
                        onClick={(e) => {
                            handleNextChart();
                        }}
                        disabled={nextButtonCooldown || loading}
                        size="small"
                    >
                        NEXT
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};


export const BalanceCard = ({ playMoney, score }) => {
    return (
        <Card variant="outlined" sx={{}}>
            <CardContent sx={{ "&:last-child": { paddingBottom: 0 }, py: 0, mt: 0.5, mb: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                    <Tooltip title="BALANCE">
                        <div>
                            <span role="img" aria-label="balance">💰</span>
                        </div>
                    </Tooltip>
                    <Typography variant="body2" style={{ marginLeft: 8 }}>
                        ${Math.round((playMoney + Number.EPSILON) * 100) / 100}
                    </Typography>
                    <Box ml={5}>
                        <Typography variant="body2" style={{ margin: 0 }}>
                            SCORE: {score}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};


export const LastVoteCard = ({ lastVoteResult, formData }) => {
    return (
        <Card variant="outlined">
            <Tooltip title="LAST VOTE RESULT">
                <CardContent sx={{ "&:last-child": { paddingBottom: 0 }, py: 0, mt: 0.5, mb: 0.5 }}>
                    {lastVoteResult === null ? (
                        <Box display="flex" alignItems="center">
                            <span role="img" aria-label="waiting">⌛</span>
                        </Box>
                    ) : lastVoteResult === "correct" ? (
                        <Box display="flex" alignItems="center">
                            <CheckCircleIcon sx={{ color: 'green' }} />
                            <Typography sx={{ marginLeft: 2 }}>
                                ${Math.round(formData.dollarDelta * 100) / 100}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center">
                            <CancelIcon sx={{ color: 'red' }} />
                            <Typography sx={{ marginLeft: 2 }}>
                                ${Math.round(formData.dollarDelta * 100) / 100}
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Tooltip>
        </Card>
    );
};


export const ChartSettings = ({ frequencyList, histlenList, horizonList, candleType, histlen, horizon, handleChartSetting, setCandleType, setHistlen, setHorizon }) => {

    return (
        <Box display="flex">
            <Tooltip title="CANDLE TYPE" placement="bottom">
                <Box>
                    <ButtonGroup variant="contained" aria-label="candle-btns">
                        {frequencyList.map((freq, idx) => (
                            <ToggleButton
                                key={idx}
                                value={freq.value}
                                selected={candleType === freq.value}
                                onClick={() => handleChartSetting(freq.value, setCandleType)}
                                size="small"
                                sx={{ px: 1, py: 0.5, mr: 0.1 }}
                            >
                                {freq.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Box>
            </Tooltip>
            <Box ml={2}>
                <Tooltip title="HISTORICAL DATA" placement="bottom">
                    <Box>
                        <ButtonGroup variant="contained" aria-label="hist-btns">
                            {histlenList.map((hist, idx) => (
                                <ToggleButton
                                    key={idx}
                                    value={hist.value}
                                    selected={histlen === hist.value}
                                    onClick={() => handleChartSetting(hist.value, setHistlen)}
                                    size="small"
                                    sx={{ px: 1, py: 0.5, mr: 0.1 }}
                                >
                                    {hist.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Box>
                </Tooltip>
            </Box>
            <Box ml={2}>
                <Tooltip title="HORIZON LENGTH" placement="bottom">
                    <Box>
                        <ButtonGroup aria-label="hrzn-btns">
                            {horizonList.map((hrzn, idx) => (
                                <ToggleButton
                                    key={idx}
                                    value={hrzn.value}
                                    selected={horizon === hrzn.value}
                                    onClick={() => handleChartSetting(hrzn.value, setHorizon)}
                                    size="small"
                                    sx={{ px: 1, py: 0.5, mr: 0.1 }}
                                >
                                    {hrzn.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    );
};
