import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ChartComponent } from "./TVChart";

const TestPage = () => {
    const [formData, setValues] = useState({
        ticker: 'SPY',
        priceDataShow: [],
        volDataShow: [],
        chartClose: 0,
        trend: '',
        dollarDelta: 0
    });

    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const API_URL = process.env.REACT_APP_API_BASE_URL;

    // fetch market data
    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${API_URL}/api/loadData/`, {
                params: {
                    candleType: 'D',
                    histLen: '1Y',
                    holdLen: '3M',
                }
            });
            setValues({
                ticker: res.data.ticker,
                priceDataShow: res.data.priceDataShow,
                volDataShow: res.data.volDataShow,
                chartClose: res.data.chartClose,
                trend: res.data.trend,
                dollarDelta: res.data.dollarDelta
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [reload]);

    const getNewData = () => {
        setReload(!reload);
    }

    return (
        <div style={{ position: 'relative', minHeight: '300px' }}>
            {loading && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Spinner animation="border" role="status" variant="primary" style={{ width: '4rem', height: '4rem' }}>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}
            <div>
                <button onClick={getNewData}>GET NEW DATA</button>
            </div>
            {!loading && (
                <React.Fragment>
                    <ChartComponent
                        candleSeriesData={formData.priceDataShow} 
                        volumeSeriesData={formData.volDataShow}
                        renderChart={reload}
                    />
                    <div>{formData.ticker}</div>
                    <div>{formData.priceDataShow.length > 0 ? formData.priceDataShow[0].time : 'first run'}</div>
                </React.Fragment>
            )}
        </div>	
    );
}

export default TestPage;
