
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

// local scripts
import ResponsiveAppBar from './components/MainNavbar'
import Login from './containers/Login';
import Signup from './containers/Signup';
import LogoutPage from './containers/Logout';
// import Activate from './containers/Activate';
import ResetPassword from './containers/ResetPassword';
// import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import UserHome from './pages/HomePage';
import About from './pages/AboutPage';
import GymPage from './pages/Gym';
import VotePage from './pages/Vote';
import Leaderboard from './pages/LeaderBoard';
import Help from './pages/Help';
import NotFoundPage from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import UnderDevelopmentPage from './pages/PageDownAlert'
import TestPage from './pages/TestPage';
import { checkAuthenticated } from './actions/auth';


const App = ({ checkAuthenticated }) => {
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             await checkAuthenticated();
    //         } catch (err) {
    //             // Handle error
    //         }
    //     };

    //     fetchData();
    // }, [checkAuthenticated]);

    return (
            <Router>
                <ResponsiveAppBar />
                <Routes>
                    <Route path='/' element={<About />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/home' element={<UserHome />} />
                    <Route path='/gym' element={<GymPage />} />
                    <Route path='/vote' element={<VotePage />} />
                    <Route path='/leaderboard' element={<UnderDevelopmentPage />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path='/logout' element={<LogoutPage />} />                    
                    <Route path='/reset_password' element={<ResetPassword />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/terms-and-conditions' element={<TermsConditions />} />
                    <Route path='/help' element={<Help />} />
                    <Route path='/404' element={<NotFoundPage />} />
                    <Route path='/test' element={<TestPage />} />
                </Routes>
            </Router>
    );
};

const mapDispatchToProps = {
    checkAuthenticated
};

export default connect(null, mapDispatchToProps)(App);
