
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { connect } from 'react-redux';
import { debounce } from "lodash";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Typography,
  Tooltip,
} from "@mui/material";

import { Alert } from '@mui/lab';
import ToggleButton from "@mui/lab/ToggleButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; // or CancelOutlined
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/system';


// Local resources
import { ChartComponent } from "./TVChart";
import { QA1, BasicInfo, ErrorBoundary, LinearDeterminate } from './Utils';

// declare freq/hist/hrzn button group
const frequencyList = [
  { name: 'D', value: 'D' },
  { name: 'W', value: 'W' },
  { name: 'M', value: 'M' },
];

const histlenList = [
  { name: '1Y', value: '1Y' },
  { name: '2Y', value: '2Y' },
  { name: '5Y', value: '5Y' },
];

const horizonList = [
  { name: '1M', value: '1M' },
  { name: '2M', value: '2M' },
  { name: '3M', value: '3M' },
];

const horizonIntMap = {
  '1M': 30,
  '2M': 60,
  '3M': 90,
};

const theme = createTheme({
  spacing: 8,
  palette: {
    common: {
      white: '#ffffff',
      // Define other colors here
    },
    // Define other theme properties here
  },
});

// Define custom styles for the button
const GreenButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3),
  color: 'white', // Text color
  backgroundColor: '#4caf50', // Green color
  '&:hover': {
    backgroundColor: '#388e3c', // Darker green color on hover
  },
}));

// Define custom styles for the button
const RedButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3),
  color: 'white', // Text color
  backgroundColor: '#f44336', // Red color
  '&:hover': {
    backgroundColor: '#d32f2f', // Darker red color on hover
  },
}));

// button disable duration 1.2 sec.
const cooldownDuration = 1200


const VotePage = ({ user_id, isAuthenticated }) => {

  const location = useLocation();
  sessionStorage.setItem('loginRedirect', location.pathname);

  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [formData, setValues] = useState({
    ticker: 'SPY',
    priceData: [],
    volData: [],
    chartClose: 0
  });
  const [candleType, setCandleType] = useState(localStorage.getItem('candleType') || frequencyList[0].value);
  const [histlen, setHistlen] = useState(localStorage.getItem('histlen') || histlenList[0].value);
  const [horizon, setHorizon] = useState(localStorage.getItem('horizon') || horizonList[0].value);
  const [loading, setLoading] = useState(false);
  const [voteButtonCooldown, setVoteButtonCooldown] = useState(false);
  const [nextButtonCooldown, setNextButtonCooldown] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [horizonInt, setHorizonInt] = useState(horizonIntMap[horizon] || 30);
  
  useEffect(() => {
    setHorizonInt(horizonIntMap[horizon] || 30);
  }, [horizon]);

  useEffect(() => {
    localStorage.setItem('candleType', candleType);
    localStorage.setItem('histlen', histlen);
    localStorage.setItem('horizon', horizon);
  }, [candleType, histlen, horizon]);

  // State for alerts
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success', // Default severity
  });

  // setting focus
  const gymPageRef = useRef(null);
  useEffect(() => {
    if (gymPageRef.current) {
      gymPageRef.current.focus();
    }
  }, []);

  // Define debounced event handler for keyboard events
  const debouncedHandleVoteKeyPress = useRef(debounce((event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      handleUserVote(event, event.key === 'ArrowUp' ? 1 : -1);
    }
  }, cooldownDuration)).current;

  const debouncedHandleNextChartKeyPress = useRef(debounce((event) => {
    if (event.key === " " || event.key === "ArrowRight") {
      handleNextChart();
    }
  }, cooldownDuration)).current;

  // Listen to UP & DOWN arrow keys
  useEffect(() => {
    const handleVoteKeyPress = (event) => {
      setShowProgressBar(true);
      setVoteButtonCooldown(true);
      setNextButtonCooldown(true);  
      debouncedHandleVoteKeyPress(event);
    };

    document.addEventListener("keydown", handleVoteKeyPress);

    return () => {
      document.removeEventListener("keydown", handleVoteKeyPress);
    };
  }, [debouncedHandleVoteKeyPress]);

  // Listen to SPACE & RIGHT arrow keys
  useEffect(() => {
    const handleNextChartKeyPress = (event) => {
      setShowProgressBar(true);
      setVoteButtonCooldown(true);
      setNextButtonCooldown(true);
      debouncedHandleNextChartKeyPress(event);
    };

    document.addEventListener("keydown", handleNextChartKeyPress);

    return () => {
      document.removeEventListener("keydown", handleNextChartKeyPress);
    };
  }, [debouncedHandleNextChartKeyPress]);

  // fetch market data
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${API_URL}/api/loadVoteData/`, {
        params: {
          candleType: candleType,
          histLen: histlen,
        }
      });
      setValues({
        ticker: res.data.ticker,
        priceData: res.data.priceData, // Default value for priceDataShow
        volData: res.data.volData, // Default value for volDataShow
        chartClose: res.data.chartClose, // Default value for chartClose
      });
    } catch (err) {
      console.log("Error fetching data:", err);
      setAlert({
        open: true,
        message: 'Failed to fetch data. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, [candleType, histlen, horizon]);  

  // handle vote buttons click
  const handleUserVote = (e, vote) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postVote(vote);
    handleNextChart();
  };

  // actions to take for next chart
  const handleNextChart = () => {
    setShowProgressBar(true);
    setVoteButtonCooldown(true);
    setNextButtonCooldown(true);
    setTimeout(() => {
      fetchData();
      setVoteButtonCooldown(false);
      setNextButtonCooldown(false);
      setShowProgressBar(false);
    }, cooldownDuration);
  };  

  // get voteCast and voteCount dates
  const getVoteDate = (hrzn) => {

    Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    var date = new Date();

    return date.addDays(hrzn)
  }

  // save registered user vote
  const postVote = async (vote) => {
    try {
      const url = `${API_URL}/api/SaveVote/`;

      const voteInfo = {
        'userid': user_id,
        'ticker': formData.ticker,
        'cast_close': Math.round(((formData.chartClose) + Number.EPSILON) * 100)/100,
        'cast_date': new Date(),
        'vote': vote,
        'horizon': horizonInt,
        'count_close': -1,
        'count_date': getVoteDate(horizonInt)
      };

      await axios.post(url, voteInfo);

      // Trigger success alert
      setAlert({
        open: true,
        message: 'Vote cast successfull!',
        severity: 'success',
      });

    } catch (error) {
      // Trigger failure alert
      console.log('post vote error: ', error)
      setAlert({
        open: true,
        message: 'Failed to save vote!',
        severity: 'error',
      });
    }

  };

  // Alert close handler
  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const handleChartSetting = (newValue, setFunction) => {
    setFunction(newValue);
  };
  
  // material-ui theme
  const theme = useTheme();
  // const classes = useStyles();

  // setup chart height
  const minHeight = 400; // Minimum height in pixels
  const dynamicHeight = window.innerHeight * 0.7; // 70% of viewport height
  const chartHeight = dynamicHeight > minHeight ? dynamicHeight : minHeight;


  return (
    <div>
    <main className="content">
      <Box mt={1}>
        <Box mb={0.5} display="flex" justifyContent="left" sx={{ backgroundColor: "#f5f5f5", p: 0 }}>
        {/* Candle, History, Horizon Button Groups */}
        <Tooltip title="CANDLE TYPE" placement="bottom">
          <ButtonGroup variant="contained" color="primary" aria-label="candle-btns">
            {frequencyList.map((freq, idx) => (
            <ToggleButton
              key={idx}
              value={freq.value}
              selected={candleType === freq.value}
              onClick={() => handleChartSetting(freq.value, setCandleType)}
              size="small"
              sx={{ px: 1, py: 0.5, mr:0.1 }}
            >
              {freq.name}
            </ToggleButton>
            ))}                
          </ButtonGroup>
        </Tooltip>
        <Box ml={2}>
          <Tooltip title="HISTORICAL DATA" placement="bottom">
            <ButtonGroup variant="contained" color="primary" aria-label="hist-btns">
              {histlenList.map((hist, idx) => (
              <ToggleButton
                key={idx}
                value={hist.value}
                selected={histlen === hist.value}
                onClick={() => handleChartSetting(hist.value, setHistlen)}
                size="small"
                sx={{ px: 1, py: 0.5, mr:0.1 }}
              >
                {hist.name}
              </ToggleButton>
              ))}                  
            </ButtonGroup>
          </Tooltip>
        </Box>
            <Box ml={2}>
              <Tooltip title="HORIZON LENGTH" placement="bottom">
                <ButtonGroup color="primary" aria-label="hrzn-btns">
                {horizonList.map((hrzn, idx) => (
                <ToggleButton
                  key={idx}
                  value={hrzn.value}
                  selected={horizon === hrzn.value}
                  onClick={() => handleChartSetting(hrzn.value, setHorizon)}
                  size="small"
                  sx={{ px: 1, py: 0.5, mr:0.1 }}
                  >
                  {hrzn.name}
                </ToggleButton>
                ))}
                </ButtonGroup>
              </Tooltip>
            </Box>
          </Box>

          {/* Chart Component */}
          <Card variant="outlined" sx={{ height: 'fit-content', mt: 1 }}>
            <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
              <ErrorBoundary>
                <div style={{ height: chartHeight}}>
                  {loading || formData.priceData.length === 0 ? (
                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <ChartComponent
                      tickerSymbol={formData.ticker}
                      candleSeriesData={formData.priceData}
                      volumeSeriesData={formData.volData}
                    />
                  )}
                </div>
              </ErrorBoundary>
              {showProgressBar && <LinearDeterminate />}
            </CardContent>
          </Card>

          {/* Vote Buttons */}
          <Box mt={3} display="flex" justifyContent="center" sx={{ backgroundColor: "#f5f5f5"}}>
            <Tooltip title={isAuthenticated ? "UP ARROW KEY" : "LOGIN TO VOTE"} placement="top">
                <Button
                  variant='outlined'
                  onClick={(e) => {
                    handleUserVote(e, 1);
                  }}
                  disabled={!isAuthenticated || loading || voteButtonCooldown}
                  size="small"
                  sx={{ bgcolor: 'green', color: 'white', '&:hover': { bgcolor: 'darkgreen' } }}
                >
                  UP
                </Button>
            </Tooltip>
            <Box ml={1}>
              <Tooltip title={isAuthenticated ? "DOWN ARROW KEY" : "LOGIN TO VOTE"} placement="top">
                <Button
                  variant='outlined'
                  onClick={(e) => {
                    handleUserVote(e, -1);
                  }}
                  disabled={!isAuthenticated || loading || voteButtonCooldown}
                  size="small"
                  sx={{ bgcolor: 'red', color: 'white', '&:hover': { bgcolor: 'darkred' } }}
                >
                  DOWN
                </Button>
              </Tooltip>
            </Box>
            <Box ml={1}>
              <Tooltip title={isAuthenticated ? "RIGHT ARROW KEY" : "LOGIN TO VOTE"} placement="top">
                <Button
                  variant='outlined'
                  color="primary"
                  onClick={(e) => {
                    handleNextChart();
                  }}
                  disabled={!isAuthenticated || loading || nextButtonCooldown}
                  size="small"
                >
                  SKIP
                </Button>
              </Tooltip>
            </Box>
          </Box>
      </Box>
    </main>

      <Snackbar
        open={alert.open}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user_id: state.auth.user_id,
});

export default connect(mapStateToProps)(VotePage);
