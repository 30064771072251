
import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h3>Page not found!</h3>
        </div>

    );
};

export default NotFoundPage;