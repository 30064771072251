import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import { logout } from '../actions/auth';

const LogoutPage = ({ logout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Handle logout when component mounts
    logout();
  }, [logout]); // Ensure useEffect runs only once on mount

  useEffect(() => {
    // Redirect to '/' after successful logout
    navigate('/');
  }, [navigate]); // Ensure useEffect runs only once after successful logout

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Typography variant="h5">Logging out...</Typography>
      <CircularProgress /> {/* Show a loading spinner while logging out */}
    </div>
  );
}

export default connect(null, { logout })(LogoutPage);
