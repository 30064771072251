import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, LinearProgress } from "@mui/material";



export const LinearDeterminate = () => {
  const [progress, setProgress] = useState(0);
  const totalDuration = 1200; // Total duration in milliseconds (3 seconds)
  const intervalDuration = 100; // Interval duration in milliseconds

  useEffect(() => {
    const increments = 100 / (totalDuration / intervalDuration);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return Math.min(oldProgress + increments, 100);
      });
    }, intervalDuration);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};



export class ErrorBoundary extends React.Component {
  constructor(props) {
      super(props);
      this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
  }

  render() {
      if (this.state.hasError) {
          // You can render any custom fallback UI
          return <h1>Something went wrong.</h1>;
      }

      return this.props.children; 
  }
};


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const BasicModal = ({
  showModal,
  handleClose
}) => {

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export const QA1 = () => {
  return(
    <div>
        <div className='col'>
          <strong>Frequency: </strong>a choice of daily, weekly, or monthly candle<br/>
          <strong>History: </strong>historical market data in years<br/>
          <strong>Horizon: </strong>length of recent data to hide in months<br/>
          <strong>Submit: </strong>submit selection each time a change is made<br/>
        </div>
    </div>
  );
};

export const QA2 = () => {
  return(
    <div>
        <div className='col'>
          <strong>UP/DOWN/SKIP: </strong>vote on trend direction<br/>
          <strong>BALANCE: </strong>Session's balance in $$<br/>
          <strong>SCORE: </strong>session's score<br/>
          <strong>SAVE SESSION: </strong>save session result if logged in<br/>
        </div>
    </div>
  );
};

