
import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { Chart } from 'react-google-charts';
import { Button, Typography, Box, Card, CardContent, Link, Grid, Tooltip } from "@mui/material";

// local scripts
import getWeb3 from './getWeb3';

import '../styles/HomePage.css';

function removeAfterAt(email) {
    if (!email) {
        return "";
    }
    const [username] = email.split('@');
    return username;
}


const UserHome = ({ isAuthenticated, user_email, user_id }) => {
    // console.log(user);
    const API_URL = process.env.REACT_APP_API_BASE_URL;

    const [records, setRecords] = useState({
        scoreSum: 0,
        scoreHist: [],
        balance: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (user_id) {
                try {
                    const scoreUrl = `${API_URL}/api/scores/?pk=${user_id}`;
                    const walletUrl = `${API_URL}/api/WalletView/?pk=${user_id}`;
                    const [scoreResponse, walletResponse] = await Promise.all([
                        axios.get(scoreUrl),
                        axios.get(walletUrl)
                    ]);
                    setRecords({
                        scoreSum: scoreResponse.data.scoreSum,
                        scoreHist: scoreResponse.data.scoreHist,
                        balance: scoreResponse.data.balance,
                    });
                    setHasWallet(walletResponse.data.flag);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [user_id, API_URL]);

    const [hasWallet, setHasWallet] = useState(false);

    const connectWallet = async () => {
        try {
            const web3 = await getWeb3();
            const accounts = await web3.eth.getAccounts();
            const webInfo = {
                'userid': user_id,
                'data': new Date(),
                'address': accounts[0],
            }
            const url = `${API_URL}/api/WalletView/`;
            await axios.post(url, webInfo);
            alert('Wallet address saved.');
            setHasWallet(true);
        } catch (error) {
            console.log('Error connecting wallet:', error);
        }
    };

    if (!isAuthenticated) {
        return (
            <Box>
                <Card sx={{ m: 10, bgcolor: 'WhiteSmoke' }}>
                    <CardContent sx={{ p: 10, textAlign: 'center' }}>
                        <Typography variant="h2">You are not signed in!</Typography>
                        <Typography variant="h5">
                            You can still practice reading charts at the <Link component={NavLink} to='/gym' underline="none">gym</Link>.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                            <Button component={NavLink} to='/login'>LOGIN</Button>
                            <Button component={NavLink} to='/signup'>SIGNUP</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Box sx={{mt: '5px'}}>
            {/* welcome message */}
            <Card>
                <CardContent sx={{ p: 0, bgcolor: 'WhiteSmoke'}}>
                    <Grid container>
                        <Grid item xs={8} sx={{ p: 5 }} >
                            <Typography variant="h2" sx={{ textAlign: 'center' }}>
                                Welcome
                            </Typography><br/>
                            <Typography variant="h4" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 'bold' }}>
                                {removeAfterAt(user_email)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ p: 5 }}>
                            <Typography variant="h5" sx={{ textAlign: 'center', p: 1 }}>Reputation: {records.scoreSum}</Typography>
                            <Typography variant="h5" sx={{ textAlign: 'center', p: 1 }}>Balance: ${records.balance}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* home and wallet box */}
            <Card sx={{mt: '5px'}}>
            <CardContent sx={{px: 5, minHeight: '50vh', bgcolor: 'WhiteSmoke'}}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h6">
                        Your past performance is displayed here. To improve your reputation head on to the Gym and practice
                        reading patterns or if you are familiar with candlestick patterns then vote.
                        Both practicing and voting affect your reputation.
                        <br/><br/>
                        For more details visit:<Link href="https://docs.chartsmaster.com/home" target="_blank"> Home Docs</Link>
                    </Typography>
                </Grid>
                <Grid item xs={4}></Grid>

            <Grid item xs={4}>
                <Typography variant="h6">
                    {hasWallet ?
                        'Your wallet is connected. CANDL rewards are distributed monthly. Click connect to add another account.' :
                        'Connect your MetaMask wallet to receive CANDL token. Refresh this page for changes to display.'
                    }
                </Typography>
                <br/>
                    {hasWallet ?
                    <></> :
                    <Tooltip title="Temporary Unavilable" placement='top'>
                        <span>
                        <Button variant="contained" onClick={connectWallet} disabled>Connect</Button>
                        </span>
                    </Tooltip>
                    }
                <br/><br/>
                <Typography variant="h6">
                    To add CANDL to MetaMask visit: 
                    <Link href="https://coin.chartsmaster.com/wallet-setup" target="_blank"> Wallet Setup</Link>
                </Typography>
            </Grid>
        </Grid>
        </CardContent>
        </Card>

        {/* chart card */}
        <Card sx={{ mt: '5px' }}>
        <CardContent sx={{bgcolor: 'WhiteSmoke', minHeight: '20vh'}}>

        {
            records.scoreHist.length === 0 ?
            <Typography variant="h6" sx={{ textAlign: 'center', p: 5}}>
                No performance statistics to display.
            </Typography>:
            <Chart
            width={'100hh'}
            height={'65vh'}
            chartType="Bar"
            loader={<div>Loading Score Chart</div>}
            data={records.scoreHist}
            options={{
                title: 'Historical Performance',
                vAxis: {
                    format: '0'
                },
                hAxis: {
                    direction: 1,
                    slantedText: true,
                    slantedTextAngle: 30,
                },
                chartArea: { height: '70%' },
                }}
            />
        }
        </CardContent>
        </Card>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user_email: state.auth.user_email,
    user_id: state.auth.user_id
});

export default connect(mapStateToProps)(UserHome);
