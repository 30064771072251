import {
    GoogleAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
} from 'firebase/auth';


import {
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    LOGOUT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from './types';

import { fbauth } from '../firebaseConnect/config';

const handleAuthFail = () => ({ type: AUTHENTICATED_FAIL });
const handleLoginFail = error => ({ type: LOGIN_FAIL, payload: error.message });
const handleSignupFail = error => ({ type: SIGNUP_FAIL, payload: error.message });
const handleResetPasswordFail = error => ({ type: RESET_PASSWORD_FAIL, payload: error.message });

// Function to check if the email format is valid
const isValidEmail = email => {
    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Function to check if the password meets the strength requirements
const isValidPassword = password => {
    // Regular expression for basic password strength check
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
};

const isValidPasswordWeak = password => {
    // Regular expression for basic password strength check (minimum length of 6 characters)
    const passwordRegex = /^.{6,}$/;
    return passwordRegex.test(password);
};


export const fbSignInWithEmailAndPassword = (email, password) => async dispatch => {
    try {
        const result = await signInWithEmailAndPassword(fbauth, email, password);
        const payload = {
            access_token: result.user.accessToken,
            refresh_token: result.user.refreshToken,
            user_email: result.user.email,
            user_id: result.user.uid
        };
        dispatch({ type: LOGIN_SUCCESS, payload: payload });

    } catch (error) {
        dispatch(handleLoginFail(error));
    }
};

export const fbRegisterWithEmailAndPassword = (email, password) => async dispatch => {
    // const navigate = useNavigate();

    try {
        if (!isValidEmail(email)) {
            throw new Error("Invalid email format");
        }

        if (!isValidPasswordWeak(password)) {
            throw new Error("Password should be at least 6 characters long");
        }

        const result = await createUserWithEmailAndPassword(fbauth, email, password);
        const payload = {
            access_token: result.user.accessToken,
            refresh_token: result.user.refreshToken,
            user_email: result.user.email,
            user_id: result.user.uid
        };
        dispatch({ type: SIGNUP_SUCCESS, payload: payload });

        // navigate('/login');
    } catch (error) {
        if (error.message === "Invalid email format" || error.message === "Password should be at least 6 characters long") {
            // Dispatch error action and trigger alert only for user-fixable errors
            dispatch(handleSignupFail(error));
            window.alert(error.message);
        } else {
            // For other errors, you might want to handle differently, e.g., logging or showing a generic error message
            console.error("An error occurred during registration:", error);
            window.alert("An error occurred during registration. Please try again later.");
        }
    }
};

export const fbSignInWithGoogle = () => async dispatch => {
    try {
        const Provider = new GoogleAuthProvider();
        const result = await signInWithPopup(fbauth, Provider);
        const payload = {
            access_token: result.user.accessToken,
            refresh_token: result.user.refreshToken,
            user_email: result.user.email,
            user_id: result.user.uid
        };
        dispatch({ type: LOGIN_SUCCESS, payload });
        // console.log(result.user.uid);

    } catch (error) {
        dispatch(handleLoginFail(error));
    }
};

export const checkAuthenticated = () => dispatch => {
    onAuthStateChanged(fbauth, user => {
        if (user) {
            dispatch({ type: AUTHENTICATED_SUCCESS });
            dispatch({ type: USER_LOADED_SUCCESS, payload: user.email });
        } else {
            dispatch(handleAuthFail());
            dispatch({ type: USER_LOADED_FAIL });
        }
    });
};

export const logout = () => async dispatch => {
    try {
        if (window.confirm("Ready to logout? You can still practice reading patterns but can't save your progress.")) {
            await signOut(fbauth);
            dispatch({ type: LOGOUT });
            return true;
        }
        return false;
    } catch (error) {
        // Handle logout error
        console.log('Logout error:', error);
        return false;
    }
};

export const fbResetPassword = email => async dispatch => {
    try {
        await sendPasswordResetEmail(fbauth, email);
        dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (error) {
        dispatch(handleResetPasswordFail(error));
    }
};
