
import React from 'react';
import FooterPage from './FooterPage';
import '../styles/Help.css';
import '../styles/FooterPage.css';
import "bootstrap/dist/css/bootstrap.min.css";


const Help = () => (
    <div className='help-main'>
        <div className='jumbotron mt-1 text-center' style={{'paddingTop': '5px', 'paddingBottom': '5px', 'marginBottom': '5px'}}>
            <h1>FAQ</h1>
        </div>
        <div className='jumbotron mt-6' style={{'marginBottom': '5px'}}>
            <div className='row'>
                <div className='col'>
                    <h4>What is a candlestick chart?</h4>
                    <p>
                        A candlestick chart is a type of bar chart that shows open, high, low, and closing prices for a trading time interval.<br/>
                        <br/>
                        <strong>Green: </strong>closing price was higher than opening price.<br/>
                        <strong>Red: </strong>closing price was lower than opening price.
                    </p>
                </div>
                <div className='col'>
                    <img src='/assets/images/red-green-candle.png' alt='img' className='ohlc-img'/>                
                </div>
            </div>
        </div>
        <div className='jumbotron mt-6' style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>What is frequency?</h4>
            <p>
                Frequency is the time interval that produces one candlestick in a chart.<br/>
                <br/>
                <strong>D/Daily: </strong>OHLC prices of a trading day create one candlestick.<br/>
                <strong>W/Weekly: </strong>OHLC prices of a trading week create one candlestick.<br/>
                <strong>M/Monthly: </strong>OHLC prices of a trading month create one candlestick.<br/>
            </p>
        </div>
        <div className='jumbotron mt-6' style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>What is history?</h4>
            <p>
                Number of years of historical market data presented in the chart. Minimum of one year and Maximum of five years.
            </p>
        </div>
        <div className='jumbotron mt-6'  style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>What is horizon?</h4>
            <p>
                Horizon is the time interval used in evaluating chartists' trend call.<br/>
                To receive positive score a prediction must happen within selected time horizon.
            </p>
        </div>
        <div className='jumbotron mt-6'  style={{'paddingRight': '50%', 'marginBottom': '5px'}}>

            <h4>How to practice reading chart patterns?</h4>
            <p>
                To practice reading candlestick charts, go to the <strong>Gym</strong> page, select your favorite Frequency/History/Horizon combination,
                submit your selection, and use UP/DOWN/SKIP buttons at the bottom of the chart to input your decision.
                Each session starts with $1000 in balance and a score of 0. Each correct prediction increases the score by one and increases the balance by
                the difference in last closing price in chart and last closing price in horizon period. Each incorrect prediction has the opposite effect.
                Skipping the chart has no effect on score or balance.
            </p>
        </div>

        <div className='jumbotron mt-6'  style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>Why date indices are hidden for charts on Gym page?</h4>
            <p>
                The purpose of this tool is to help traders with reading chart patterns and removing date indices will
                reduce biases influenced by historical events and it will increase the focus on patterns.
                Therefore for each chart displayed on Gym page a random interval is chosen from available market history and
                dates are replaced with candlestick number.
            </p>
        </div>

        <div className='jumbotron mt-6' style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>How balance and score is calculated?</h4>
            <p>
                If closing price at the end of horizon period is greater than last closing price in chart by more than one percent then
                trend is upward and clicking up button is the correct prediction. Similarly, if closing price at the end of horizon period
                is less than last closing price in chart by more than one percent then trend is downward and clicking down button is the
                correct prediction. A move in price by less than one percent is ignored and has no effect on score but it affects the balance.
            </p>
        </div>
        <div className='jumbotron mt-6' style={{'paddingRight': '50%', 'marginBottom': '5px'}}>
            <h4>How to vote?</h4>
            <p>
                You can vote at <strong>Vote</strong> page if you have successfully signed in.
                The chart setup is similar to <strong>Gym</strong> page however history is
                up to date and horizon period is in future. Also stock ticker symbols are masked in charts to prevent voters from biased votes.
                All votes are presented in leaderboard page by order of popularity, voter time horizon, and time since the vote was casted.
            </p>
        </div>
        <div className='jumbotron mt-6' style={{'paddingRight': '50%', 'marginBottom': '20px'}}>
            <h4>Why should I vote?</h4>
            <p>
                At the end of each horizon, casted votes will be examined and voter reputation will be updated.
                So, if you are comfortable with reading chart patterns, voting can improve your reputation as well as informing other users
                and visitors from your point of view in the market.
            </p>
        </div>
        <div>
            <FooterPage />
        </div>
    </div>
)

export default Help;