
import {
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL
} from '../actions/types';

const initialState = {
    access_token: localStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    isAuthenticated: localStorage.getItem('access_token') !== null,
    isRegistered: localStorage.getItem('access_token') !== null,
    user_email: localStorage.getItem('user_email') ? JSON.parse(localStorage.getItem('user_email')) : null,
    user_id: localStorage.getItem('user_id') || null,
    error: null
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATED_SUCCESS:
        case LOGIN_SUCCESS:
            if (payload && payload.access_token && payload.refresh_token && payload.user_email && payload.user_id) {

                localStorage.setItem('access_token', payload.access_token);
                localStorage.setItem('refresh_token', payload.refresh_token);
                localStorage.setItem('user_email', JSON.stringify(payload.user_email));
                localStorage.setItem('user_id', payload.user_id);
                return {
                    ...state,
                    isAuthenticated: true,
                    access_token: payload.access_token,
                    refresh_token: payload.refresh_token,
                    user_email: payload.user_email,
                    user_id: payload.user_id,
                    error: null
                };
        }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                error: null
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isRegistered: true,
                isAuthenticated: false,
                error: null
            };
        case AUTHENTICATED_FAIL:
        case USER_LOADED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: payload
            };
        case SIGNUP_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_id')
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                isAuthenticated: false,
                user_email: null,
                user_id: null,
                error: payload
            };
        default:
            return state;
    }
}
