import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

const pages = ['About', 'Gym', 'Vote', 'Leaderboard'];
const authPages = ['Login', 'Signup'];
const userPages = ['Home', 'Logout']

function ResponsiveAppBar({ isAuthenticated }) {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [profileMenu, setProfileMenu] = useState(authPages);

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
    setAnchorElUser(null);
  };

  useEffect(() => {
    setProfileMenu(isAuthenticated ? userPages : authPages);
  }, [isAuthenticated]);

  
  return (
    <AppBar position="static" sx={{ backgroundColor: 'grey' }}>
      {/* <Container maxWidth='xl'> */}
        <Toolbar style={{ minHeight: '40px', padding: 0}}>

          {/* Logo, Menu, and Profile Box */}
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', px:1 }}>

            {/* Logo and Menu Box */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

            {/* Logo Box */}
            <Box sx={{ textAlign: 'center', mr: { xs: 5, md: 5 } }}>
              <NavLink to='/'>
                <img alt='CM' src='/assets/images/logo128.png' width='30px' height='30px' />
              </NavLink>
            </Box>

            {/* Menu Box */}
            <Box sx={{ display: 'flex', alignItems: 'center', width: '30px', height: '30px' }}>
              {/* Show menu icon */}
              <IconButton
                size="large"
                aria-label="open menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                color="inherit"
                sx={{ display: { xs: 'flex', md: 'none' }, p: 0 }}
              >
                <MenuIcon />
              </IconButton>

              {/* Responsive Menu */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseMenu}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    component={NavLink}
                    to={`/${page.toLowerCase()}`}
                    onClick={handleCloseMenu}
                  >
                    {page}
                  </MenuItem>
                ))}
              </Menu>

              {/* Show menu items on medium and larger screens */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    component={NavLink}
                    to={`/${page.toLowerCase()}`}
                    sx={{ color: 'white', display: 'block', textDecoration: 'none', width: 'auto', mx: 1 }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Box>
            </Box>

            {/* Profile box */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '30px', height: '30px' }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon sx={{ color: 'white', width: '30px', height: '30px' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '30px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseMenu}
              >
                {profileMenu.map((setting) => (
                  <MenuItem
                    key={setting}
                    component={NavLink}
                    to={`/${setting.toLowerCase()}`}
                    onClick={handleCloseMenu}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ResponsiveAppBar);
